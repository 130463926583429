.settings {
  width: 100%;
}

main.content {
  padding: 30px 0;
  background-color: #ffffff;
  height: inherit;
  overflow: auto;
}

.delete_form {
  padding: 45px 40px 0;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
}


.center_content {
  margin: 0 auto;
  max-width: 880px;
}

.card h2 {
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
}

.card_body {
  padding: 20px 35px 10px 35px;
}

.card_footer {
  padding: 20px;
}

.card {
  border-radius: 15px;
  background: #fff;
  margin-bottom: 20px;
}

.colorsInner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.colorItem {
  display: flex;
  width: calc(25% - 10px);
  align-items: flex-end;
}