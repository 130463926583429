.btn {
  margin: 0;
  cursor: pointer;
  border: 0;
  outline: none;
  padding: 5px 15px;
  border-radius: 4px;
  background: #4a7b90;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  transition: transform 0.3s ease;
  box-sizing: border-box;
}

.btn:disabled,
.btn[disabled] {
  background-color: #dfdfdf !important;
  color: #9f9f9f !important;
  cursor: default;
}
.btn:hover {
  transform: translateY(-2px);
}

.modal_btn {
  composes: btn;
  display: block;
  padding: 11px 15px;
  width: 100%;
  font-weight: 700;
}

.menu_btn {
  composes: btn;
  color: #4a7b90;
  background-color: #fff;
  padding: 10px 25px;
  font-weight: 700;
}

.promo_btn {
  /*composes: btn;*/
  /*margin: 60px 0;*/
  /*padding: 15px 25px;*/
  /*font-size: 22px;*/
  /*font-weight: 700;*/

  position: relative;
  border: 4px solid transparent;
  border-radius: 16px;
  background: linear-gradient(orange, violet);
  background-clip: padding-box;
  padding: 10px;
  /* just to show box-shadow still works fine */
  box-shadow: 0 3px 9px black, inset 0 0 9px white;
}

.promo_btn::after {
  position: absolute;
  top: -4px; bottom: -4px;
  left: -4px; right: -4px;
  background: linear-gradient(red, blue);
  content: '';
  z-index: -1;
  border-radius: 16px;
}

.project_add_btn {
  composes: btn;
  background: #f70553;
  margin-left: 20px;
  font-size: 16px;
  white-space: nowrap;
}
.project_add_btn i {
  margin-right: 10px;
  margin-top: 2px;
}

.project_view_btn {
  composes: btn;
  color: #222;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}

.project_view_btn i {
  font-size: 20px;
  line-height: 34px;
  color: #222;
}

.project_view_btn:disabled,
.project_view_btn[disabled] {
  background: none !important;
  cursor: default;
}

.project_view_btn:disabled i,
.project_view_btn[disabled] i {
  color: #999 !important;
}

.project_view_btn:hover {
  transform: translateY(0);
}

.project_view_btn:not(disabled):hover {
  background-color: #4a7b90;
  color: #fff;
}

.project_view_btn:not(disabled):hover i {
  color: #fff;
}
/* 
.project_view_btn {
  composes: btn;
} */

.btn_cancel {
  composes: btn;
  background: #bdbdbd;
  color: #fff;
}

.btn_save {
  composes: btn;
  background: #f70553;
  color: #fff;
}
.btn_delete {
  composes: btn;
  background: #ffaac5;
  color: rgb(32, 32, 32);
}
