@import "./styles/reset.css";
@import "./styles/variables.css";

/*@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700&display=swap");*/
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@200;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Dots:wght@200;400;600;700&display=swap");

body {
  overflow: hidden;
  height: 100vh;
}

*{
  box-sizing: border-box;
}

#root {
  max-width: 100%;
  min-width: 320px;
  height: 100vh;
  position: relative;
  overflow: auto;

  font-size: 12px;
  font-weight: 400;
  font-family: Syne, sans-serif;
}

.mantine-Modal-close svg {
  fill: #333;
}
