.panelContainer {
  background-color: #fff;
  left: 0;
  bottom: 0;
  user-select: none;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e5e5e5;
  cursor: default;
  z-index: 2;
  min-width: 240px;
  max-width: 400px;
  flex-shrink: 0;
}
.content {
  flex-grow: 1;
  position: relative;
}

.verticalFlexContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;
}

.tabsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 40px;
}

.tab:first-child,
.tab:first-child:after {
  /*padding-left: 16px;*/
}
.tab {
  padding: 8px 8px;
  visibility: hidden;
  position: relative;
}
.tab:after {
  font-size: 14px;
  font-weight: 600;
  content: attr(data-label);
  position: absolute;
  top: 0;
  left: 13px;
  padding: 0;
  visibility: visible;
  color: #697786;
}
.tab:hover:after {
  color: #333;
}
.tabActive:after {
  content: attr(data-label);
  font-weight: 600;
  font-size: 14px;
  color: #222730;
}

.rightTab {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 2px;
  height: 100%;
  padding: 0 16px;
  flex-grow: 1;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  fill: #b3b3b3;
  color: #b3b3b3;
}
.rightTab:hover .chevron {
  fill: #333;
  color: #333;
}

.pagesPanelContent {
  width: 100%;
  flex-shrink: 1;
  position: relative;
  cursor: default;
}
.pagesPanelContent:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.newPageButton {
  margin: 4px 8px;
}
._iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  fill: #6549f7;
  color: #6549f7;
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: transparent;
}
.iconButtonEnabled:hover {
  background-color: #f7f5ff;
  fill: #6549f7;
  color: #6549f7;
  border-radius: 10px;
}
.iconButtonEnabled:focus {
  border-color: #18a0fb;
  box-shadow: inset 0 0 0 1px #18a0fb;
  border-radius: 10px;
}

.layersList {
  position: relative;
  margin-top: -4px;
  min-height: 100px;
  max-height: 200px;
}

.pageRow {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
}
.pageRow:hover {
  background-color: #f7f5ff;
}

.pageRow .pageRowSvg {
  opacity: 0;
}

.pageRowSelected .pageRowSvg {
  opacity: 1;
}
.pageRowSelected {
  font-weight: 500;
}

.pagesRowSpacer {
  height: 32px;
  line-height: 32px;
  position: relative;
  overflow: visible;
}
.pageRowSpacerInsertAfterBottom:after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #000;
  pointer-events: none;
  bottom: 1px;
}
.pageRowSpacerInsertBefore:after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #000;
  pointer-events: none;
  top: -2px;
}
.pageRowSpacerInsertAfter:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #000;
  pointer-events: none;
}

.pageRowSpacerInsertBeforeTop:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #000;
  pointer-events: none;
}

.pageRowSvg {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
  margin-top: -1px;
  flex-shrink: 0;
}

.renameableNode {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 8px;
  height: 32px;
  box-sizing: border-box;
  justify-content: space-between;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;

  /* content: "";
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 8px;
  height: 32px;
  box-sizing: border-box;
  justify-content: space-between;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  flex:1; */
}

.renameableNodeText {
  height: 32px;
  line-height: 32px;
  order: 1;
  flex: 0 0 auto;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  /* background-color: transparent; */
  /* padding-left: 10px; */
}

.renameableNodeInput {
  height: 30px;
  margin: 0;
  border: 1px solid var(--fg-focus);
  outline: 1px solid var(--fg-focus);
  outline-offset: -2px;
  padding: 0 0 0 7px;
  line-height: 24px;
  border-radius: 2px;
  flex-grow: 1;
  box-sizing: border-box;
  background-color: #fff;
}

.renameableNodeTextActive {
  border: 2px solid rgb(71, 160, 244);
  height: 28px;
  flex: 1;
}
.renameableNodeTextSelected {
  font-weight: 600;
}
.resizeLineContainer {
  position: relative;
}
.resizeLine {
  position: absolute;
  cursor: ns-resize;
  width: 100%;
  height: 10px;
  top: -10px;
}

.objectPanelContent {
  position: relative;
  width: 100%;
  cursor: default;
  flex-grow: 1;
  overflow: hidden;
  background-color: #fff;
}

.assetsContainer {
  position: relative;
  width: 100%;
  cursor: default;
  flex-grow: 1;
  overflow: hidden;
  background-color: #fff;
}
.assetsHeaderContainer {
  display: flex;
  align-items: center;
  height: 40px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 10px 0;
}
.searchWithViewModeToggle {
  position: relative;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.assetsSearchInput {
  height: 100%;
  width: 100%;
  /* box-sizing: border-box; */
  flex: 1;
  cursor: auto;
  background: url(pages/Components/svg/search_medium.svg) no-repeat center left 16px;
  padding: 0 8px 0 35px;
}
.toggleComponent {
  height: 32px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-right: 8px;
}
.panelType {
  border: 1px solid transparent;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.panelBody {
  height: 28px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: 0.3s all;
  border: 1px solid transparent;
  cursor: pointer;
  padding-left: 10px;
}

.panelBodyHovered {
  background-color: rgba(238, 139, 96, 0.48);
  border-radius: 10px;
}

.panelBodySelected {
  background-color: rgba(67, 209, 194, 0.48);
  border-radius: 10px;
}

.panelBodyEmpty{
  display: flex;
  width: 90%;
  height: 200px;
  align-items: center;
  justify-content: center;
  background-color: #F7F5FF;
  font-family: Syne;
  font-weight: bold;
  color: #222730;
  font-size: 16px;
  padding: 0 24px;
  text-align: center;
  margin: 10px auto;
  border-radius: 20px;
}

.panelBody1 {
  height: 36px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: 0.3s all;
  border: 1px solid transparent;
  cursor: pointer;
  padding-left: 10px;
  border-radius: 10px;
}

.panelBodyUppendEvent {
  border-bottom: 1px solid #7f61ff;
}
.tmpNode {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
}
.tmpNodeActive {
  border-bottom: 1px solid #7f61ff;
}
.panelChildren {
  padding-left: 16px;
  display: none;
}
.panelChildrenActive {
  display: block;
}
.panelType .panelButton {
  visibility: hidden;
  width: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.panelBody:hover .panelButton {
  visibility: visible;
  width: 20px;
}
.panelTypeActiveParent {
  background-color: rgba(238, 245, 249);
}
.panelTypeActive {
  background-color: rgb(221, 234, 246);
}

.topLevel {
  font-weight: bold;
  color: #000000;
  place-self: center;
}
.otherLevel {
  color: #7f61ff;
}
.panelEmptyButton {
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.panelDot {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #cccccc;
}
.panelArrow {
  width: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.panelArrow span {
  opacity: 0.3;
}
.panelLeftIcon {
  width: 16px;
  display: flex;
  align-items: center;
}
.emptyArrow {
  opacity: 0;
  width: 16px;
}
.treeWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reactContextmenu {
  position: fixed;
  width: 150px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.reactContextmenuItem:hover {
  background: #cccccc;
}
.reactContextMenuDisable:hover {
  background: transparent;
}
.reactContextmenuItem {
  font-size: 12px;
  display: block;
  text-decoration: none;
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}
.reactContextMenuDisable {
  opacity: 0.3;
  cursor: none;
}
.reactContextmenu-item span {
  margin-left: 10px;
}
.emptyTree {
  margin: 15px auto;
  padding: 15px 0;
  width: 95%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  margin: 0 auto;
}
