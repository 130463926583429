/*
Scroll Containers
*/
.scrollContainer::-webkit-scrollbar {
  width: 7px;
}
.scrollContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}
.outerContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.scrollContainer {
  width: 100%;
  height: 100%;
  max-height: inherit;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}
.innerContainer {
  width: 100%;
  min-height: 100%;
}

/* Iterator */

.contentContainer {
  position: relative;
  padding-bottom: 50px;
}

.itemRow {
  width: 100%;
  height: 32px;
  line-height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.itemRow[data-item] {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 2px;
  border: 1px solid transparent;
}
.itemRow[data-item]:hover {
  background-color: #F7F5FF;
  border-radius: 10px;
}

.itemRowGrid[data-item] {
  display: flex;
  width: 30%;
  margin-left: 2.5%;
  aspect-ratio: 1;
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  color: #7e7c7c;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
}

.itemRowGrid[data-item] header {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  display: contents;
}
.itemRowGrid[data-item]:hover {
  background-color: #F7F5FF;
  color: #000;
}
/*.itemRow[data-header]:hover {*/
/*  background-color: #f0f0f0;*/
/*}*/
.itemRow header {
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Syne';
  font-size: 14px;
}
.itemRow[data-header] span {
  position: relative;
  width: 20px;
  color: #000;
  fill: #000;
  opacity: 0.3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
}

.itemRow[data-item] span {
  width: 24px;
  height: 24px;
}
.itemRow[data-item] header {
  position: relative;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  margin-left: 16px;
}

.itemRow[data-header] {
  margin-bottom: 6px;
}
.itemRow[data-header="h1"] header {
  font-size: 14px;
  font-weight: 600;
}
.itemRow[data-header="h3"] {
  padding-left: 10px;
}

.itemRow[data-header]:hover span,
.expandCaretOpen[data-header] span {
  opacity: 0.7;
}

.expandCaretOpen:hover{
  background-color: #F7F5FF;
  border-radius: 10px;
}

.expandCaretOpenHover:hover{
  background-color: #F7F5FF;
  border-radius: 10px;
}

/* Drag */

.draggingThumbGrid {
  position: fixed !important;
  cursor: default;
  z-index: 10;
  pointer-events: auto;

  /* display: inline-block; */
  width: unset !important;
  background-color: #f0f0f0;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  padding-left: 3px;
}
.draggingThumbGrid span {
  border-color: #333 !important;
}
.draggingThumbGrid:hover {
  border-color: transparent !important;
}
