.fontInput {
  width: 100%;
  display: grid;
  gap: 12px;
}

.fontInputHorizontal {
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: flex-end;
}

.fontInputHorizontalGroup {
  gap: 0;
}
