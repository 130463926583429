.wrapper {
  display: flex;
  flex-flow: column;
  height: 100vh;
  position: relative;
  flex: 1 1 auto;
  font-size: 11px;
}

.row {
  display: flex;
  height: calc(100% - var(--toolbar-height));
  justify-content: "space-between";
  width: 100%;
}

.edge_resize {
  left: 0 !important;
  width: 200% !important;
}
.edge {
  cursor: ew-resize;
  position: fixed;
  right: 0;
  top: var(--toolbar-height);
  height: 100%;
  width: 6px;
  margin-left: -3px;

  z-index: 2;
}
