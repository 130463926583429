@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Syne:wght@200;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zen+Dots:wght@200;400;600;700&display=swap);
.toolbar_toolbar__5d7mB {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: var(--toolbar-height);
  pointer-events: none;
  flex-direction: row;
  z-index: 8;
  color: #fff;
  background-color: var(--bg-toolbar);
  flex-shrink: 0;
  padding: 16px 32px;
}

.toolbar_flexibleSpace__1TZhQ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.toolbar_flexibleSpace__1TZhQ > * {
  pointer-events: all;
}

.toolbar_ellipsis__1sI_n {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
}

.toolbar_chevron_container__i5yMe {
  padding: 4px 16px 0;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}
.toolbar_chevron_container__i5yMe .toolbar_svg_container__3Zvrq {
  transition: padding-top 0.15s;
  padding-top: 0;
  margin: auto;
}

.toolbar_chevron_container__i5yMe:hover .toolbar_svg_container__3Zvrq {
  padding-top: 6px;
}

.toolbar_dropdown__31lPK {
  position: fixed;
  top: 55px;
  right: 25px;
  width: 220px;
  z-index: 10;
}

.toolbar_dropdown_box__3o2IP {
  position: relative;
  background: var(--bg-toolbar);
  border-radius: 4px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.toolbar_dropdown_box__3o2IP:after {
  content: "";
  display: block;
  clear: both;
  position: absolute;
  left: 50%;
  bottom: 100%;
  width: 0;
  height: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 5px solid var(--bg-toolbar);
}

.toolbar_dropdown__31lPK ul {
  margin: 0;
  padding: 10px 0;
  border-top: 1px solid #777;
}

.toolbar_dropdown__31lPK ul:first-child {
  border: none;
}

.toolbar_dropdown__31lPK ul a,
.toolbar_dropdown__31lPK ul button {
  display: flex;
  margin: 0;
  padding: 5px 20px;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  color: #fff;
  background: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.toolbar_dropdown__31lPK ul a:hover,
.toolbar_dropdown__31lPK ul button:hover,
.toolbar_Button__y1EVc:hover {
  background-color: var(--bg-toolbar-hover);
  border-radius: 10px;
  color: #000;
}

.toolbar_Button__y1EVc {
  margin: 0;
  padding: 0 10px;
  border: 0;
  background: none;
  color: #fff;
  font-size: 18px;
  transition: all 0.3s ease;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.toolbar_Button__y1EVc svg,
.toolbar_textButton__1g_3i svg {
  margin-right: 10px;
}

.toolbar_sideButtonGroup__1wG0F {
  white-space: nowrap;
  display: flex;
  height: 100%;
  min-width: 0;
  pointer-events: all;
}

.toolbar_userIconView__2l_Fg {
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.toolbar_userIconAvatar__1QP7F {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.toolbar_textButtonContainer__jVpVc {
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
}

.toolbar_textButton__1g_3i {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #fff;
  color: #fff;
  background: none;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 18px;
  border-radius: 10px;
}

.toolbar_textButton__1g_3i:hover {
  background-color: #fff;
  color: #333;
}

.wysiwyg {
  width: 100%;
  background: #ccc;
  padding-bottom: 48px;
}

.wysiwyg .screen-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.wysiwyg .zoom-container {
  bottom: 20px;
  right: 20px;
  z-index: 20;
}

.wysiwyg .topline {
  padding: 12px 24px;
  width: 100%;
  border-bottom: 1px solid #333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.icon-hidden {
  display: none;
}

.frame-wrapper {
  position: relative;
  margin-top: 50px;
}

.frame-wrapper img {
  position: absolute;
  max-width: 115.1%;
  z-index: 2;
  top: -3.19%;
  left: -7.54%;
  pointer-events: none;
  z-index: 3;
}

.frame-wrapper .device-keyboard {
  left: 0;
  bottom: 0;
  width: 100%;
  top: auto;
  z-index: 2;
}

.loader-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f8;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}

.content {
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content .planet {
  width: 65%;
  height: 65%;
  background-color: #6549f7;
  border-radius: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
  -webkit-animation: planet 5s ease infinite alternate;
          animation: planet 5s ease infinite alternate;
}
@-webkit-keyframes planet {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}
@keyframes planet {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}
.content .planet .ring {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  background-color: #d7bfe3;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform-origin: 33% center;
          transform-origin: 33% center;
  box-shadow: 2px -10px 0px rgba(0, 0, 0, 0.1), inset -5px -10px 0px rgba(0, 0, 0, 0.1);
  -webkit-animation: ring 3s ease infinite;
          animation: ring 3s ease infinite;
  /* transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px); */
}
@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
            transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
  }
  100% {
    -webkit-transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
            transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
  }
}
@keyframes ring {
  0% {
    -webkit-transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
            transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
  }
  100% {
    -webkit-transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
            transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
  }
}
.content .planet .ring:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 30px;
  border-radius: 100%;
  background-color: #7ea1bf;
  z-index: 2;
  left: calc(0px - 5px);
  box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.2);
}
.content .planet .ring:after {
  content: "";
  position: absolute;
  width: 240px;
  height: 240px;
  border-radius: 100%;
  background-color: #cb9ad7;
  box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
}
.content .planet .cover-ring {
  position: absolute;
  width: 100%;
  height: 50%;
  border-bottom-left-radius: 80%;
  border-bottom-right-radius: 80%;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  -webkit-transform: translate(0px, -17px) translateZ(150px);
          transform: translate(0px, -17px) translateZ(150px);
  background-color: #6549f7;
  z-index: 2;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
}
.content .planet .spots {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  -webkit-transform: translateZ(300px);
          transform: translateZ(300px);
  z-index: 2;
}
.content .planet .spots span {
  width: 30px;
  height: 30px;
  background-color: #3c4359;
  position: absolute;
  border-radius: 100%;
  box-shadow: inset -2px 3px 0px rgba(0, 0, 0, 0.3);
  -webkit-animation: dots 5s ease infinite alternate;
          animation: dots 5s ease infinite alternate;
}
@-webkit-keyframes dots {
  0% {
    box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: inset 3px 3px 0px rgba(0, 0, 0, 0.3);
  }
}
@keyframes dots {
  0% {
    box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: inset 3px 3px 0px rgba(0, 0, 0, 0.3);
  }
}
.content .planet .spots span:nth-child(1) {
  top: 20px;
  right: 50px;
}
.content .planet .spots span:nth-child(2) {
  top: 40px;
  left: 50px;
  width: 15px;
  height: 15px;
}
.content .planet .spots span:nth-child(3) {
  top: 80px;
  left: 20px;
  width: 25px;
  height: 25px;
}
.content .planet .spots span:nth-child(4) {
  top: 80px;
  left: 90px;
  width: 40px;
  height: 40px;
}
.content .planet .spots span:nth-child(5) {
  top: 160px;
  left: 70px;
  width: 15px;
  height: 15px;
}
.content .planet .spots span:nth-child(6) {
  top: 165px;
  left: 125px;
  width: 10px;
  height: 10px;
}
.content .planet .spots span:nth-child(7) {
  top: 90px;
  left: 150px;
  width: 15px;
  height: 15px;
}
.content p {
  color: #bacbd9;
  font-size: 14px;
  z-index: 2;
  position: absolute;
  bottom: -20px;
  font-family: "Roboto Mono", monospace;
  -webkit-animation: text 4s ease infinite;
          animation: text 4s ease infinite;
  width: 100px;
  text-align: center;
}
@-webkit-keyframes text {
  0% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
  25% {
    letter-spacing: 3px;
    color: #7ea1bf;
  }
  50% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
  75% {
    letter-spacing: 3px;
    color: #7ea1bf;
  }
  100% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
}
@keyframes text {
  0% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
  25% {
    letter-spacing: 3px;
    color: #7ea1bf;
  }
  50% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
  75% {
    letter-spacing: 3px;
    color: #7ea1bf;
  }
  100% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    letter-spacing: 0px;
    color: #bacbd9;
  }
}

.fontmanager_fontManager__1g2BQ {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0;
  bottom: 0;
  z-index: 2;
}

.fontmanager_fontManager_Content__11hF6 {
  width: 500px;
  height: 400px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  box-sizing: "border-box";
}

.fontmanager_fontManager_Header__FYKch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.fontmanager_fontManager_Header_title__3bLl6 {
  font-size: 24px;
  color: #333;
}

.fontmanager_fontManager_List__1qM89 {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 12px;
  height: 360px;
}

.fontmanager_fontItem__2CV42 {
  padding: 12px 18px;
  font-size: 18px;
  /*border: 1px solid #000;*/
  border-radius: 8px;
  margin: 4px 0;
}

.fontmanager_fontItem__2CV42:hover {
  background-color:  #F7F5FF;
  color: #000;
  cursor: pointer;
}

.fontInput_fontInput__1UkiF {
  width: 100%;
  display: grid;
  grid-gap: 12px;
  gap: 12px;
}

.fontInput_fontInputHorizontal__MQMDL {
  width: 100%;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  align-items: flex-end;
}

.fontInput_fontInputHorizontalGroup__cqoZs {
  grid-gap: 0;
  gap: 0;
}

.iconmanager_iconManager_List__3pEGM:hover{
    background: #F7F5FF;
}
/* @import "./button.module.css"; */

.modal_modal__5Ffqc {
  position: relative;
  width: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 5px 5px 7px 0 rgba(0, 0, 0, 0.3);
  padding: 25px;
}

.modal_close_modal__2yZ0b {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 15px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #222;
  background: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal_close_modal__2yZ0b:hover {
  background: #6549F7;
  color: #fff;
}

.modal_login_form__183G0 {
  padding: 10px 40px 0;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 450px;
  text-align: center;
}

.modal_title__11zz- {
  margin-bottom: 25px;
  font-size: 30px;
}

.modal_input_group__Lfkmt,
.modal_login_form__183G0 p {
  margin-bottom: 20px;
}

.modal_login_form__183G0 label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.modal_input_field___epH2 {
  -webkit-appearance: none;
          appearance: none;
  display: block;
  width: 100%;
  text-align: left;
  border: 1px solid #757575;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 20px;
}
.modal_error_msg__1KtbT {
  color: red;
  font-size: 14px;
  height: 25px;
}

.settings_settings__H-bF_ {
  width: 100%;
}

main.settings_content__3LI_l {
  padding: 30px 0;
  background-color: #ffffff;
  height: inherit;
  overflow: auto;
}

.settings_delete_form__3hM06 {
  padding: 45px 40px 0;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
}


.settings_center_content__2a0_r {
  margin: 0 auto;
  max-width: 880px;
}

.settings_card__3c8RF h2 {
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
}

.settings_card_body__yizHd {
  padding: 20px 35px 10px 35px;
}

.settings_card_footer__30Ki7 {
  padding: 20px;
}

.settings_card__3c8RF {
  border-radius: 15px;
  background: #fff;
  margin-bottom: 20px;
}

.settings_colorsInner__r1P4c {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}

.settings_colorItem__3oU8l {
  display: flex;
  width: calc(25% - 10px);
  align-items: flex-end;
}
.style_wrapper__-Xyse {
  display: flex;
  flex-flow: column;
  height: 100vh;
  position: relative;
  flex: 1 1 auto;
  font-size: 11px;
}

.style_row__NqrF_ {
  display: flex;
  height: calc(100% - var(--toolbar-height));
  justify-content: "space-between";
  width: 100%;
}

.style_edge_resize__14kBn {
  left: 0 !important;
  width: 200% !important;
}
.style_edge__3Yeui {
  cursor: ew-resize;
  position: fixed;
  right: 0;
  top: var(--toolbar-height);
  height: 100%;
  width: 6px;
  margin-left: -3px;

  z-index: 2;
}

.leftpanel_panelContainer__3DCph {
  background-color: #fff;
  left: 0;
  bottom: 0;
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e5e5e5;
  cursor: default;
  z-index: 2;
  min-width: 240px;
  max-width: 400px;
  flex-shrink: 0;
}
.leftpanel_content__3vawx {
  flex-grow: 1;
  position: relative;
}

.leftpanel_verticalFlexContainer__UzHiC {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;
}

.leftpanel_tabsHeader__3nS1G {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 40px;
}

.leftpanel_tab__1Xi15:first-child,
.leftpanel_tab__1Xi15:first-child:after {
  /*padding-left: 16px;*/
}
.leftpanel_tab__1Xi15 {
  padding: 8px 8px;
  visibility: hidden;
  position: relative;
}
.leftpanel_tab__1Xi15:after {
  font-size: 14px;
  font-weight: 600;
  content: attr(data-label);
  position: absolute;
  top: 0;
  left: 13px;
  padding: 0;
  visibility: visible;
  color: #697786;
}
.leftpanel_tab__1Xi15:hover:after {
  color: #333;
}
.leftpanel_tabActive__1Xvv1:after {
  content: attr(data-label);
  font-weight: 600;
  font-size: 14px;
  color: #222730;
}

.leftpanel_rightTab__1HTYl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 2px;
  height: 100%;
  padding: 0 16px;
  flex-grow: 1;
}

.leftpanel_ellipsis__1q9ZJ {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.leftpanel_chevron__1vxYw {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  fill: #b3b3b3;
  color: #b3b3b3;
}
.leftpanel_rightTab__1HTYl:hover .leftpanel_chevron__1vxYw {
  fill: #333;
  color: #333;
}

.leftpanel_pagesPanelContent__VvHMq {
  width: 100%;
  flex-shrink: 1;
  position: relative;
  cursor: default;
}
.leftpanel_pagesPanelContent__VvHMq:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.leftpanel_newPageButton__2MiF1 {
  margin: 4px 8px;
}
.leftpanel__iconButton__22C58 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  fill: #6549f7;
  color: #6549f7;
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: transparent;
}
.leftpanel_iconButtonEnabled__8ujE8:hover {
  background-color: #f7f5ff;
  fill: #6549f7;
  color: #6549f7;
  border-radius: 10px;
}
.leftpanel_iconButtonEnabled__8ujE8:focus {
  border-color: #18a0fb;
  box-shadow: inset 0 0 0 1px #18a0fb;
  border-radius: 10px;
}

.leftpanel_layersList__1nief {
  position: relative;
  margin-top: -4px;
  min-height: 100px;
  max-height: 200px;
}

.leftpanel_pageRow__2z2x6 {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
}
.leftpanel_pageRow__2z2x6:hover {
  background-color: #f7f5ff;
}

.leftpanel_pageRow__2z2x6 .leftpanel_pageRowSvg__3n3ny {
  opacity: 0;
}

.leftpanel_pageRowSelected__MgTHF .leftpanel_pageRowSvg__3n3ny {
  opacity: 1;
}
.leftpanel_pageRowSelected__MgTHF {
  font-weight: 500;
}

.leftpanel_pagesRowSpacer__2UFe2 {
  height: 32px;
  line-height: 32px;
  position: relative;
  overflow: visible;
}
.leftpanel_pageRowSpacerInsertAfterBottom__LSU8C:after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #000;
  pointer-events: none;
  bottom: 1px;
}
.leftpanel_pageRowSpacerInsertBefore__3GI3L:after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #000;
  pointer-events: none;
  top: -2px;
}
.leftpanel_pageRowSpacerInsertAfter__1FZQg:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #000;
  pointer-events: none;
}

.leftpanel_pageRowSpacerInsertBeforeTop__1loq_:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #000;
  pointer-events: none;
}

.leftpanel_pageRowSvg__3n3ny {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
  margin-top: -1px;
  flex-shrink: 0;
}

.leftpanel_renameableNode__1x9fN {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 8px;
  height: 32px;
  box-sizing: border-box;
  justify-content: space-between;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;

  /* content: "";
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 8px;
  height: 32px;
  box-sizing: border-box;
  justify-content: space-between;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  flex:1; */
}

.leftpanel_renameableNodeText__3LE9C {
  height: 32px;
  line-height: 32px;
  order: 1;
  flex: 0 0 auto;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  /* background-color: transparent; */
  /* padding-left: 10px; */
}

.leftpanel_renameableNodeInput__2GbtJ {
  height: 30px;
  margin: 0;
  border: 1px solid var(--fg-focus);
  outline: 1px solid var(--fg-focus);
  outline-offset: -2px;
  padding: 0 0 0 7px;
  line-height: 24px;
  border-radius: 2px;
  flex-grow: 1;
  box-sizing: border-box;
  background-color: #fff;
}

.leftpanel_renameableNodeTextActive__1P451 {
  border: 2px solid rgb(71, 160, 244);
  height: 28px;
  flex: 1 1;
}
.leftpanel_renameableNodeTextSelected__2tjKc {
  font-weight: 600;
}
.leftpanel_resizeLineContainer__1rUzz {
  position: relative;
}
.leftpanel_resizeLine__3HGhQ {
  position: absolute;
  cursor: ns-resize;
  width: 100%;
  height: 10px;
  top: -10px;
}

.leftpanel_objectPanelContent__2-9Zn {
  position: relative;
  width: 100%;
  cursor: default;
  flex-grow: 1;
  overflow: hidden;
  background-color: #fff;
}

.leftpanel_assetsContainer__3rp6L {
  position: relative;
  width: 100%;
  cursor: default;
  flex-grow: 1;
  overflow: hidden;
  background-color: #fff;
}
.leftpanel_assetsHeaderContainer__1PctR {
  display: flex;
  align-items: center;
  height: 40px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 10px 0;
}
.leftpanel_searchWithViewModeToggle__16N2W {
  position: relative;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.leftpanel_assetsSearchInput__3AbsG {
  height: 100%;
  width: 100%;
  /* box-sizing: border-box; */
  flex: 1 1;
  cursor: auto;
  background: url(/static/media/search_medium.07a0e2fc.svg) no-repeat center left 16px;
  padding: 0 8px 0 35px;
}
.leftpanel_toggleComponent__1sZ8q {
  height: 32px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-right: 8px;
}
.leftpanel_panelType__3w-8H {
  border: 1px solid transparent;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.leftpanel_panelBody__1q5Hi {
  height: 28px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: 0.3s all;
  border: 1px solid transparent;
  cursor: pointer;
  padding-left: 10px;
}

.leftpanel_panelBodyHovered__3WAv8 {
  background-color: rgba(238, 139, 96, 0.48);
  border-radius: 10px;
}

.leftpanel_panelBodySelected__3NszB {
  background-color: rgba(67, 209, 194, 0.48);
  border-radius: 10px;
}

.leftpanel_panelBodyEmpty__a9LpG{
  display: flex;
  width: 90%;
  height: 200px;
  align-items: center;
  justify-content: center;
  background-color: #F7F5FF;
  font-family: Syne;
  font-weight: bold;
  color: #222730;
  font-size: 16px;
  padding: 0 24px;
  text-align: center;
  margin: 10px auto;
  border-radius: 20px;
}

.leftpanel_panelBody1__3COYk {
  height: 36px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: 0.3s all;
  border: 1px solid transparent;
  cursor: pointer;
  padding-left: 10px;
  border-radius: 10px;
}

.leftpanel_panelBodyUppendEvent__zjvmz {
  border-bottom: 1px solid #7f61ff;
}
.leftpanel_tmpNode__2PWnM {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
}
.leftpanel_tmpNodeActive__Bkclc {
  border-bottom: 1px solid #7f61ff;
}
.leftpanel_panelChildren__2DqF- {
  padding-left: 16px;
  display: none;
}
.leftpanel_panelChildrenActive__229pd {
  display: block;
}
.leftpanel_panelType__3w-8H .leftpanel_panelButton__2qc_w {
  visibility: hidden;
  width: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.leftpanel_panelBody__1q5Hi:hover .leftpanel_panelButton__2qc_w {
  visibility: visible;
  width: 20px;
}
.leftpanel_panelTypeActiveParent__23gor {
  background-color: rgba(238, 245, 249);
}
.leftpanel_panelTypeActive__2vdHY {
  background-color: rgb(221, 234, 246);
}

.leftpanel_topLevel__1y8HI {
  font-weight: bold;
  color: #000000;
  place-self: center;
}
.leftpanel_otherLevel__1az2L {
  color: #7f61ff;
}
.leftpanel_panelEmptyButton__3ApFh {
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.leftpanel_panelDot__1VtOB {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #cccccc;
}
.leftpanel_panelArrow__2Rnvp {
  width: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.leftpanel_panelArrow__2Rnvp span {
  opacity: 0.3;
}
.leftpanel_panelLeftIcon__2qrup {
  width: 16px;
  display: flex;
  align-items: center;
}
.leftpanel_emptyArrow__xn8F3 {
  opacity: 0;
  width: 16px;
}
.leftpanel_treeWrap__2od_L {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.leftpanel_reactContextmenu__2f4Ow {
  position: fixed;
  width: 150px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.leftpanel_reactContextmenuItem__1rlgS:hover {
  background: #cccccc;
}
.leftpanel_reactContextMenuDisable__pFHm4:hover {
  background: transparent;
}
.leftpanel_reactContextmenuItem__1rlgS {
  font-size: 12px;
  display: block;
  text-decoration: none;
  padding: 5px 10px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}
.leftpanel_reactContextMenuDisable__pFHm4 {
  opacity: 0.3;
  cursor: none;
}
.leftpanel_reactContextmenu-item__2cPT_ span {
  margin-left: 10px;
}
.leftpanel_emptyTree__22ZLF {
  margin: 15px auto;
  padding: 15px 0;
  width: 95%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftpanel_loader__1b8bo {
  margin: 0 auto;
}

/*
Scroll Containers
*/
.list_scrollContainer__2l980::-webkit-scrollbar {
  width: 7px;
}
.list_scrollContainer__2l980::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}
.list_outerContainer__2WNy3 {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.list_scrollContainer__2l980 {
  width: 100%;
  height: 100%;
  max-height: inherit;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overscroll-behavior: contain;
}
.list_innerContainer__ey2y0 {
  width: 100%;
  min-height: 100%;
}

/* Iterator */

.list_contentContainer__fyzk2 {
  position: relative;
  padding-bottom: 50px;
}

.list_itemRow__dPnrb {
  width: 100%;
  height: 32px;
  line-height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.list_itemRow__dPnrb[data-item] {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 2px;
  border: 1px solid transparent;
}
.list_itemRow__dPnrb[data-item]:hover {
  background-color: #F7F5FF;
  border-radius: 10px;
}

.list_itemRowGrid__1ZWHG[data-item] {
  display: flex;
  width: 30%;
  margin-left: 2.5%;
  aspect-ratio: 1;
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  color: #7e7c7c;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
}

.list_itemRowGrid__1ZWHG[data-item] header {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  display: contents;
}
.list_itemRowGrid__1ZWHG[data-item]:hover {
  background-color: #F7F5FF;
  color: #000;
}
/*.itemRow[data-header]:hover {*/
/*  background-color: #f0f0f0;*/
/*}*/
.list_itemRow__dPnrb header {
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Syne';
  font-size: 14px;
}
.list_itemRow__dPnrb[data-header] span {
  position: relative;
  width: 20px;
  color: #000;
  fill: #000;
  opacity: 0.3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
}

.list_itemRow__dPnrb[data-item] span {
  width: 24px;
  height: 24px;
}
.list_itemRow__dPnrb[data-item] header {
  position: relative;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  margin-left: 16px;
}

.list_itemRow__dPnrb[data-header] {
  margin-bottom: 6px;
}
.list_itemRow__dPnrb[data-header="h1"] header {
  font-size: 14px;
  font-weight: 600;
}
.list_itemRow__dPnrb[data-header="h3"] {
  padding-left: 10px;
}

.list_itemRow__dPnrb[data-header]:hover span,
.list_expandCaretOpen__203LF[data-header] span {
  opacity: 0.7;
}

.list_expandCaretOpen__203LF:hover{
  background-color: #F7F5FF;
  border-radius: 10px;
}

.list_expandCaretOpenHover__3Xyp5:hover{
  background-color: #F7F5FF;
  border-radius: 10px;
}

/* Drag */

.list_draggingThumbGrid__1NSwj {
  position: fixed !important;
  cursor: default;
  z-index: 10;
  pointer-events: auto;

  /* display: inline-block; */
  width: unset !important;
  background-color: #f0f0f0;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  padding-left: 3px;
}
.list_draggingThumbGrid__1NSwj span {
  border-color: #333 !important;
}
.list_draggingThumbGrid__1NSwj:hover {
  border-color: transparent !important;
}

.button_btn__3IbFR {
  margin: 0;
  cursor: pointer;
  border: 0;
  outline: none;
  padding: 5px 15px;
  border-radius: 4px;
  background: #4a7b90;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  box-sizing: border-box;
}

.button_btn__3IbFR:disabled,
.button_btn__3IbFR[disabled] {
  background-color: #dfdfdf !important;
  color: #9f9f9f !important;
  cursor: default;
}
.button_btn__3IbFR:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.button_modal_btn__1-JbU {
  display: block;
  padding: 11px 15px;
  width: 100%;
  font-weight: 700;
}

.button_menu_btn__27lLB {
  color: #4a7b90;
  background-color: #fff;
  padding: 10px 25px;
  font-weight: 700;
}

.button_promo_btn__3vUt1 {
  /*composes: btn;*/
  /*margin: 60px 0;*/
  /*padding: 15px 25px;*/
  /*font-size: 22px;*/
  /*font-weight: 700;*/

  position: relative;
  border: 4px solid transparent;
  border-radius: 16px;
  background: linear-gradient(orange, violet);
  background-clip: padding-box;
  padding: 10px;
  /* just to show box-shadow still works fine */
  box-shadow: 0 3px 9px black, inset 0 0 9px white;
}

.button_promo_btn__3vUt1::after {
  position: absolute;
  top: -4px; bottom: -4px;
  left: -4px; right: -4px;
  background: linear-gradient(red, blue);
  content: '';
  z-index: -1;
  border-radius: 16px;
}

.button_project_add_btn__3MQJS {
  background: #f70553;
  margin-left: 20px;
  font-size: 16px;
  white-space: nowrap;
}
.button_project_add_btn__3MQJS i {
  margin-right: 10px;
  margin-top: 2px;
}

.button_project_view_btn__MekkQ {
  color: #222;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}

.button_project_view_btn__MekkQ i {
  font-size: 20px;
  line-height: 34px;
  color: #222;
}

.button_project_view_btn__MekkQ:disabled,
.button_project_view_btn__MekkQ[disabled] {
  background: none !important;
  cursor: default;
}

.button_project_view_btn__MekkQ:disabled i,
.button_project_view_btn__MekkQ[disabled] i {
  color: #999 !important;
}

.button_project_view_btn__MekkQ:hover {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.button_project_view_btn__MekkQ:not(disabled):hover {
  background-color: #4a7b90;
  color: #fff;
}

.button_project_view_btn__MekkQ:not(disabled):hover i {
  color: #fff;
}
/* 
.project_view_btn {
  composes: btn;
} */

.button_btn_cancel__2kGUb {
  background: #bdbdbd;
  color: #fff;
}

.button_btn_save__ovlfR {
  background: #f70553;
  color: #fff;
}
.button_btn_delete__2COp7 {
  background: #ffaac5;
  color: rgb(32, 32, 32);
}

/* @import "./../button.module.css";
@import "./style.module.css"; */

.HandlingProject_input_field__1eyKa {
  -webkit-appearance: none;
          appearance: none;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  padding: 0 10px;
  height: 34px;
}

select {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  padding: 0 20px 0 10px;
  height: 34px;
}

.HandlingProject_textarea_input_field__2phPL {
  width: 100% !important;
  line-height: 1.25;
  padding: 6px 10px;
  height: 94px;
}

.HandlingProject_btn__3ReDR {
  font-size: 16px;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

/* main content */
.HandlingProject_main_content__2uPC_ {
  padding: 100px 30px 30px 310px;
}

/* card */
.HandlingProject_card__382P7 {
  border-radius: 15px;
  background: #fff;
  margin-bottom: 20px;
}

.HandlingProject_card_header__js5Zh {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
}

.HandlingProject_card_body__1xKOI {
  padding: 20px 35px 10px 35px;
}

.HandlingProject_card_footer__94ew7 {
  padding: 20px;
}

.HandlingProject_center_content__3wy2e {
  margin: 0 auto;
  max-width: 880px;
}

.HandlingProject_card__382P7 h2 {
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
}

.HandlingProject_form_row__3-Dzk {
  display: flex;
  align-items: flex-start;
}

.HandlingProject_form_row_wrap__3OFJG {
  flex-wrap: wrap;
}

.HandlingProject_form_row__3-Dzk label {
  padding-right: 10px;
  width: 350px;
  line-height: 34px;
}

.HandlingProject_form_row__3-Dzk .HandlingProject_input_field__1eyKa,
.HandlingProject_form_row__3-Dzk select {
  width: 100%;
}

.HandlingProject_form_row__3-Dzk .HandlingProject_image__3w5vJ {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.HandlingProject_form_row__3-Dzk .HandlingProject_image__3w5vJ img {
  display: block;
  max-width: 210px;
}

/*.checkbox_field,*/
/*.btn_group {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  width: 100%;*/
/*}*/

.HandlingProject_checkbox_field__2t0Dc input {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

.HandlingProject_form_row__3-Dzk .HandlingProject_checkbox_field__2t0Dc label {
  width: 100%;
  padding: 0;
}

/*.btn_group {*/
/*  justify-content: flex-end;*/
/*}*/

.HandlingProject_btn_group__12j3R button {
  margin-left: 20px;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
select,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: inherit;
  outline: none;
  letter-spacing: inherit;
  text-decoration: none;
  cursor: inherit;
}

ul {
  list-style: none;
}
svg path:not([fill="none"]) {
  fill: inherit;
}

/*
global variables 
*/
:root {
  --bg-primary-btn: #18a0fb;
  --bg-toolbar: #222730;
  --bg-toolbar-hover: #fff;
  --bg-toolbar-active: #18a0fb;
  --bg-toolbar-selection: #18a0fb;
  --fg-toolbar: hsla(0, 0%, 100%, 0.8);
  --fg-toolbar-hover: #fff;
  --fg-toolbar-active: #fff;
  --fg-toolbar-disabled: hsla(0, 0%, 100%, 0.5);
  --fg-toolbar-filename: #fff;
  --fg-toolbar-foldername: #aaa;
  --fg-toolbar-chevron: #fff;
  --fg-toolbar-unsavedicon: hsla(0, 0%, 100%, 0.4);
  --fg-toolbar-login-button: #fff;
  --fg-toolbar-login-button-border: hsla(0, 0%, 100%, 0.8);
  --fg-toolbar-login-button-active: hsla(0, 0%, 100%, 0.4);
  --fg-toolbar-community-icon: hsla(0, 0%, 100%, 0.4);
  --fg-toolbar-community-icon-active: hsla(0, 0%, 100%, 0.8);
  --fg-toolbar-placeholder: hsla(0, 0%, 100%, 0.4);
  --toolbar-height: 80px;
  --toolbar-unread-dot-top: 8px;
  --fg-keyboard-shortcuts: #aaa;
  --bg-keyboard-shortcuts: #2c2c2c;
  --fg-keyboard-shortcuts-tab: #aaa;
  --fg-keyboard-shortcuts-tab-hover: #d4d4d4;
  --fg-keyboard-shortcuts-divider: transparent;
  --fg-keyboard-shortcuts-tab-border: #444;
  --bg-overlay: #222;
  --bg-overlay-active: #18a0fb;
  --bg-overlay-outline: rgba(0, 0, 0, 0.1);
  --bg-overlay-inner-outline: #000;
  --fg-overlay: #fff;
  --fg-overlay-active: #fff;
  --fg-overlay-secondary: hsla(0, 0%, 100%, 0.4);
  --fg-overlay-sep: hsla(0, 0%, 100%, 0.2);
  --fg-overlay-right: #fff;
  --fg-focus: #18a0fb;
  --fg-select-chevron: #b3b3b3;
  --fg-select-chevron-hover: #333;
  --bg-progress-bar: #18a0fb;
  --bg-slider-track: #050505;
  --fg-slider-thumb: #050505;
  --bg-pointer-modal: #18a0fb;
  --fg-survey-active: #18a0fb;
  --fg-voice-ghost-button: hsla(0, 0%, 100%, 0.8);
  --fg-voice-ghost-button-hover: hsla(0, 0%, 100%, 0.8);
  --fg-voice-ghost-button-disabled: hsla(0, 0%, 100%, 0.2);
  --voice-text-light: hsla(0, 0%, 100%, 0.4);
  --voice-text-dark: hsla(0, 0%, 100%, 0.8);
  --voice-text-dark-hover: hsla(0, 0%, 100%, 0.8);
}

/*@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700&display=swap");*/

body {
  overflow: hidden;
  height: 100vh;
}

*{
  box-sizing: border-box;
}

#root {
  max-width: 100%;
  min-width: 320px;
  height: 100vh;
  position: relative;
  overflow: auto;

  font-size: 12px;
  font-weight: 400;
  font-family: Syne, sans-serif;
}

.mantine-Modal-close svg {
  fill: #333;
}

