.toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: var(--toolbar-height);
  pointer-events: none;
  flex-direction: row;
  z-index: 8;
  color: #fff;
  background-color: var(--bg-toolbar);
  flex-shrink: 0;
  padding: 16px 32px;
}

.flexibleSpace {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;
}
.flexibleSpace > * {
  pointer-events: all;
}

.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
}

.chevron_container {
  padding: 4px 16px 0;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}
.chevron_container .svg_container {
  transition: padding-top 0.15s;
  padding-top: 0;
  margin: auto;
}

.chevron_container:hover .svg_container {
  padding-top: 6px;
}

.dropdown {
  position: fixed;
  top: 55px;
  right: 25px;
  width: 220px;
  z-index: 10;
}

.dropdown_box {
  position: relative;
  background: var(--bg-toolbar);
  border-radius: 4px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.dropdown_box:after {
  content: "";
  display: block;
  clear: both;
  position: absolute;
  left: 50%;
  bottom: 100%;
  width: 0;
  height: 0;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 5px solid var(--bg-toolbar);
}

.dropdown ul {
  margin: 0;
  padding: 10px 0;
  border-top: 1px solid #777;
}

.dropdown ul:first-child {
  border: none;
}

.dropdown ul a,
.dropdown ul button {
  display: flex;
  margin: 0;
  padding: 5px 20px;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  color: #fff;
  background: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.dropdown ul a:hover,
.dropdown ul button:hover,
.Button:hover {
  background-color: var(--bg-toolbar-hover);
  border-radius: 10px;
  color: #000;
}

.Button {
  margin: 0;
  padding: 0 10px;
  border: 0;
  background: none;
  color: #fff;
  font-size: 18px;
  transition: all 0.3s ease;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.Button svg,
.textButton svg {
  margin-right: 10px;
}

.sideButtonGroup {
  white-space: nowrap;
  display: flex;
  height: 100%;
  min-width: 0;
  pointer-events: all;
}

.userIconView {
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.userIconAvatar {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.textButtonContainer {
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
}

.textButton {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #fff;
  color: #fff;
  background: none;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 18px;
  border-radius: 10px;
}

.textButton:hover {
  background-color: #fff;
  color: #333;
}
