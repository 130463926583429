.fontManager {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0;
  bottom: 0;
  z-index: 2;
}

.fontManager_Content {
  width: 500px;
  height: 400px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  box-sizing: "border-box";
}

.fontManager_Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.fontManager_Header_title {
  font-size: 24px;
  color: #333;
}

.fontManager_List {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 12px;
  height: 360px;
}

.fontItem {
  padding: 12px 18px;
  font-size: 18px;
  /*border: 1px solid #000;*/
  border-radius: 8px;
  margin: 4px 0;
}

.fontItem:hover {
  background-color:  #F7F5FF;
  color: #000;
  cursor: pointer;
}
