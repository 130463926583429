.wysiwyg {
  width: 100%;
  background: #ccc;
  padding-bottom: 48px;
}

.wysiwyg .screen-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.wysiwyg .zoom-container {
  bottom: 20px;
  right: 20px;
  z-index: 20;
}

.wysiwyg .topline {
  padding: 12px 24px;
  width: 100%;
  border-bottom: 1px solid #333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.icon-hidden {
  display: none;
}

.frame-wrapper {
  position: relative;
  margin-top: 50px;
}

.frame-wrapper img {
  position: absolute;
  max-width: 115.1%;
  z-index: 2;
  top: -3.19%;
  left: -7.54%;
  pointer-events: none;
  z-index: 3;
}

.frame-wrapper .device-keyboard {
  left: 0;
  bottom: 0;
  width: 100%;
  top: auto;
  z-index: 2;
}
