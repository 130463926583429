/* @import "./button.module.css"; */

.modal {
  position: relative;
  width: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 5px 5px 7px 0 rgba(0, 0, 0, 0.3);
  padding: 25px;
}

.close_modal {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 15px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #222;
  background: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close_modal:hover {
  background: #6549F7;
  color: #fff;
}

.login_form {
  padding: 10px 40px 0;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 450px;
  text-align: center;
}

.title {
  margin-bottom: 25px;
  font-size: 30px;
}

.input_group,
.login_form p {
  margin-bottom: 20px;
}

.login_form label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.input_field {
  appearance: none;
  display: block;
  width: 100%;
  text-align: left;
  border: 1px solid #757575;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 20px;
}
.error_msg {
  color: red;
  font-size: 14px;
  height: 25px;
}
