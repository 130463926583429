/*
global variables 
*/
:root {
  --bg-primary-btn: #18a0fb;
  --bg-toolbar: #222730;
  --bg-toolbar-hover: #fff;
  --bg-toolbar-active: #18a0fb;
  --bg-toolbar-selection: #18a0fb;
  --fg-toolbar: hsla(0, 0%, 100%, 0.8);
  --fg-toolbar-hover: #fff;
  --fg-toolbar-active: #fff;
  --fg-toolbar-disabled: hsla(0, 0%, 100%, 0.5);
  --fg-toolbar-filename: #fff;
  --fg-toolbar-foldername: #aaa;
  --fg-toolbar-chevron: #fff;
  --fg-toolbar-unsavedicon: hsla(0, 0%, 100%, 0.4);
  --fg-toolbar-login-button: #fff;
  --fg-toolbar-login-button-border: hsla(0, 0%, 100%, 0.8);
  --fg-toolbar-login-button-active: hsla(0, 0%, 100%, 0.4);
  --fg-toolbar-community-icon: hsla(0, 0%, 100%, 0.4);
  --fg-toolbar-community-icon-active: hsla(0, 0%, 100%, 0.8);
  --fg-toolbar-placeholder: hsla(0, 0%, 100%, 0.4);
  --toolbar-height: 80px;
  --toolbar-unread-dot-top: 8px;
  --fg-keyboard-shortcuts: #aaa;
  --bg-keyboard-shortcuts: #2c2c2c;
  --fg-keyboard-shortcuts-tab: #aaa;
  --fg-keyboard-shortcuts-tab-hover: #d4d4d4;
  --fg-keyboard-shortcuts-divider: transparent;
  --fg-keyboard-shortcuts-tab-border: #444;
  --bg-overlay: #222;
  --bg-overlay-active: #18a0fb;
  --bg-overlay-outline: rgba(0, 0, 0, 0.1);
  --bg-overlay-inner-outline: #000;
  --fg-overlay: #fff;
  --fg-overlay-active: #fff;
  --fg-overlay-secondary: hsla(0, 0%, 100%, 0.4);
  --fg-overlay-sep: hsla(0, 0%, 100%, 0.2);
  --fg-overlay-right: #fff;
  --fg-focus: #18a0fb;
  --fg-select-chevron: #b3b3b3;
  --fg-select-chevron-hover: #333;
  --bg-progress-bar: #18a0fb;
  --bg-slider-track: #050505;
  --fg-slider-thumb: #050505;
  --bg-pointer-modal: #18a0fb;
  --fg-survey-active: #18a0fb;
  --fg-voice-ghost-button: hsla(0, 0%, 100%, 0.8);
  --fg-voice-ghost-button-hover: hsla(0, 0%, 100%, 0.8);
  --fg-voice-ghost-button-disabled: hsla(0, 0%, 100%, 0.2);
  --voice-text-light: hsla(0, 0%, 100%, 0.4);
  --voice-text-dark: hsla(0, 0%, 100%, 0.8);
  --voice-text-dark-hover: hsla(0, 0%, 100%, 0.8);
}
