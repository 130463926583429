/* @import "./../button.module.css";
@import "./style.module.css"; */

.input_field {
  appearance: none;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  padding: 0 10px;
  height: 34px;
}

select {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  padding: 0 20px 0 10px;
  height: 34px;
}

.textarea_input_field {
  composes: input_field;
  width: 100% !important;
  line-height: 1.25;
  padding: 6px 10px;
  height: 94px;
}

.btn {
  font-size: 16px;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

/* main content */
.main_content {
  padding: 100px 30px 30px 310px;
}

/* card */
.card {
  border-radius: 15px;
  background: #fff;
  margin-bottom: 20px;
}

.card_header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
}

.card_body {
  padding: 20px 35px 10px 35px;
}

.card_footer {
  padding: 20px;
}

.center_content {
  margin: 0 auto;
  max-width: 880px;
}

.card h2 {
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
}

.form_row {
  display: flex;
  align-items: flex-start;
}

.form_row_wrap {
  composes: form_row;
  flex-wrap: wrap;
}

.form_row label {
  padding-right: 10px;
  width: 350px;
  line-height: 34px;
}

.form_row .input_field,
.form_row select {
  width: 100%;
}

.form_row .image {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.form_row .image img {
  display: block;
  max-width: 210px;
}

/*.checkbox_field,*/
/*.btn_group {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  width: 100%;*/
/*}*/

.checkbox_field input {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

.form_row .checkbox_field label {
  width: 100%;
  padding: 0;
}

/*.btn_group {*/
/*  justify-content: flex-end;*/
/*}*/

.btn_group button {
  margin-left: 20px;
}
